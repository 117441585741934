import React, {useState} from "react";
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import Layout from "@components/kaigai-fx/layout"
import FixedFoot from "@components/kaigai-fx/fixedFooter.js";
import Seo from "@components/seo"
import parse from "html-react-parser"
import LazyLoad from 'react-lazy-load';

// Import SVG
import icon_post_SVG1 from '@images/kaigai-fx/post_icon1.svg';
import icon_post_SVG2 from '@images/kaigai-fx/post_icon2.svg';
import icon_post_SVG3 from '@images/kaigai-fx/post_icon3.svg';
import ogpimage from '@images/kaigai-fx/ogp.png';

// コンポーネント呼び出し
import { SearchCategList } from "@components/kaigai-fx/search-categList.js"; // カテゴリ検索機能
import { WordSearch } from "@components/kaigai-fx/word-search.js";
import { ProfileContents } from "@components/kaigai-fx/profile.js";
import { FaqComponent } from "@components/kaigai-fx/faq-component.js"
import { RelatedContents } from "@components/kaigai-fx/related-article.js"; // 関連記事取得・形成
import { BannerDataComponent } from "@components/kaigai-fx/bannerDataComponent.js";
import { ArchiveVisualComponent } from "@components/kaigai-fx/archiveVisualComponent.js";
import { BlogTableContentsComponent } from "@components/kaigai-fx/blog-tableContents-component.js";
import { BlogContentComponent } from "@components/kaigai-fx/blog-contents-component.js";
import Slider from "@components/kaigai-fx/slider.js";


const FxBlogPostTemplate = ({pageContext}) => {

  const indexUrl = '/kaigai-fx/';
  const name = pageContext.title; // 該当記事名
  const slug = pageContext.slug; // 該当Slug
  const searchList = SearchCategList().Main; // カテゴリ検索リスト(components/search-categList.js)

  // バナー情報取得（初回レンダリング時のみ作動）
  const [bannerArray_top] = useState(() => BannerDataComponent('banner', 'banner_area_2', pageContext.bannerData));
  const [bannerArray_side] = useState(() => BannerDataComponent('banner', 'banner_area_3', pageContext.bannerData));

  // ワード検索
  const wordSearchContentPC = WordSearch().PC;
  const wordSearchContentMB = WordSearch().MB;

  // プロフィール
  const profilePC = ProfileContents().PC 
  const profileMB = ProfileContents().MB

  // 表示データ
  const date = pageContext.date; // 作成日
  const modified = pageContext.modified; // 更新日

  // 大カテゴリ、中カテゴリ選定
  let setMainCateg = pageContext.categoryData.nodes.filter(category => category.wpParent === null); // メインカテゴリ
  setMainCateg = setMainCateg.sort((a, b) => (b.count) - (a.count))[0]; // 記事が多い順に並び替え

  let setSubCateg = pageContext.categoryData.nodes.filter(category => category.wpParent !== null)[0]; // サブカテゴリ
  
  // パンくず設定 文字数制限設定(テキスト, 制限文字数)
  function shortText(text, len) {
    let shortText;
    if ( text.length > len ) {shortText = text.substr( 0, len) + ' ...';} else {shortText = text}
    return shortText
  }

  // タグ表示設定
  let tagArray = pageContext.tagData.nodes;
  tagArray = tagArray.filter(item => item.name.indexOf('Q&A') === -1); // Q&Aタグ削除

  // アイキャッチ画像設定
  const eyecatchArray = {categoryName: setSubCateg.name, textData: pageContext.eyecat, title: pageContext.title}
  const eyecData = ArchiveVisualComponent(eyecatchArray, 'eyecatch', pageContext.archiveVisualData);

  // 関連記事取得(components/related-article.js)
  const relatedList = RelatedContents(pageContext, tagArray, [slug], pageContext.archiveVisualData);
  
  // 目次生成
  const contentData = pageContext.content;
  const tableContents = BlogTableContentsComponent(contentData);

  // 本文データ生成
  const [textContents] = useState(() => BlogContentComponent(contentData, pageContext.linkSetData));
 
  // FAQデータ生成
  const faqContents = FaqComponent(setSubCateg.slug, pageContext.faqtagData.nodes, pageContext.allFaqData).postData; // faqのHTML情報
  const faqJsonData = FaqComponent(setSubCateg.slug, pageContext.faqtagData.nodes, pageContext.allFaqData).jsonData; // faqの構造化データ

  // 日時形式変換用関数
  function formatDate(dateString) {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const monthIndex = date.getMonth();
    const day = date.getDate();
    return `${year}年${monthIndex + 1}月${day}日`;
  }

  return (
  <>
    <Layout>
      <Seo
        title={pageContext.title}
        description={parse(pageContext.excerpt)[0].props.children}
        ogpUrl={process.env.ORIGINURL + indexUrl + slug + '/'}
        ogpSiteName={pageContext.title}
        // ogpImage={process.env.ORIGINURL + pageContext.image?.node.localFile.childImageSharp.gatsbyImageData.images.fallback.src || process.env.ORIGINURL + ogpimage}
        ogpImage={pageContext.image? 
          process.env.ORIGINURL + pageContext.image?.node.localFile.childImageSharp.gatsbyImageData.images.fallback.src || process.env.ORIGINURL + ogpimage
          :
          eyecData.ogpimg
        }
        ogpImageWidth={pageContext.image?.node?.width || ''}
        ogpImageHeight={pageContext.image?.node?.height || ''}
        ogpImageType={pageContext.image?.node?.mimeType || ''}
        modifiedDate={pageContext.modified || ''}
        publishedDate={pageContext.date || ''}
        wpPost = "true"
        jsonLDFaqData = {faqJsonData}
      />

      <article className="blog-post" itemScope itemType="http://schema.org/Article">
        
        <div className="md:max-w-6xl md:flex justify-between mx-auto pt-0 md:pt-12 post_slider">
          
          {/*1 column */}
          <div className="max-w-3xl  px-0 md:px-5 ">

            {/* バナー画像（上部） */}
            <div className="mx-auto max-w-[1920px] mb-8">
              {bannerArray_top? <Slider imageArray={bannerArray_top}/>:<></>}
            </div>

            <div className="max-w-3xl pb-5 md:px-0 px-5 mb-10 md:pb-0 md:mb-0">
              {/* 画像 */}
              <div className="mt-0 mx-auto overflow-hidden rounded-blog flex items-center aspect-[5/2]" style={{ maxWidth: 730}}>
              {/* <div className="mt-0 mx-auto overflow-hidden rounded-blog flex items-center md:max-h-[290px] max-h-[150px]" style={{ maxWidth: 730}}></div> */}
                {pageContext.image?.node.localFile?  
                  <GatsbyImage
                    image={getImage(pageContext.image.node.localFile.childImageSharp.gatsbyImageData)} alt={name} 
                    className=''>
                  </GatsbyImage>
                  :
                  <div className='relative overflow-hidden rounded-blog'>
                    <div className='absolute h-full w-full flex justify-center items-center z-10'>
                      <p className={`relative ${eyecData.textColor} ${eyecData.fontSize.mb} ${eyecData.fontSize.pc} ${eyecData.fontSize.max} font-[900] text-center`} style={{top:`${eyecData.position}px`}}>
                        {eyecData.text2 !== '' && eyecData.text2 !== undefined?
                          <p className='relative top-[12px] md:top-[18px] leading-[1.2]'>{eyecData.text1}<br/>{eyecData.text2}</p>
                          :
                          <>{eyecData.text1}</>
                        }
                      </p>
                    </div>
                    <img className="rounded-blog mb-4 h-auto w-full object-cover scale-[1.2]" alt={eyecData.alt} src={eyecData.img}/>
                  </div>
                  // <></>
                }
              </div>

              {/* 日付 */}
              <div className="mt-3 text-[12px] flex items-center mb-3">
                <LazyLoad className='' width={13} offset={300}>
                  <img className="h-auto" width="18.72" height="18.72" alt="icon2" src={icon_post_SVG2}/>
                </LazyLoad>
                <span className="px-2"><time>{formatDate(date)}</time></span>
                <LazyLoad className='flex items-center' height={18.72} width={18.72} offset={300}>
                  <img className="pl-2 h-auto" width="18.72" height="18.72" alt="icon1" src={icon_post_SVG1}/>
                </LazyLoad>
                <span className="px-2"><time>{formatDate(modified)}</time></span>
              </div>
          
              <div className="text-[12px] mb-0 border-b-[1px] border-solid border-[#36A8C3] pb-3">
      
                {/* パンくず */}
                <div className="flex">
                  {/* <p className="flex items-center md:h-[20px] h-full">
                    <LazyLoadImage className="pr-[8px] pt-[1px]" alt="icon3" src={icon_post_SVG3}/>
                  </p> */}
                  <LazyLoad className='flex items-center md:h-[20px] h-full' offset={300}>
                    <img className="pr-[8px] md:pt-[1px] pt-[3px]" alt="icon3" src={icon_post_SVG3}/>
                  </LazyLoad>
                  <div className="flex mb-5 flex-wrap">
                    <p><a href={`${indexUrl}categories/${setMainCateg.slug}/`} className="px-[2px] hover:text-gray-400 leading-4 ">{setMainCateg.name}</a></p>
                    <p><span className="pr-[1px] mx-0 my-auto inset-y-0 md:m-0">＞</span><a href={`${indexUrl}categories/${setSubCateg?.slug}/`} className="px-[2px] hover:text-gray-400 leading-4 ">{setSubCateg?.name}</a></p>
                    <p className="hidden md:block pr-[1px] md:pt-[1px]"><span className="pr-[1px] mx-0 my-auto inset-y-0 md:m-0 leading-4">＞</span>{name}</p>
                    <p className="md:hidden pr-[1px] "><span className="pr-[1px] mx-0 my-auto inset-y-0 md:m-0 leading-4">＞</span>{shortText(name, 16)}</p>
                  </div>
                </div>

                {/* タグ */}
                <div className="flex flex-wrap">
                  {tagArray.map((result) => 
                    <div className="mb-4">
                      <a href={`${indexUrl}tags/${result.slug}/`} className=" mb-2 mr-2 text-[#fff] bg-[#3E3D3D] text-[13px] rounded px-2 py-1 pb-2 text-center">{result.name}</a>
                    </div>
                  )}
                </div>
              
              </div>
    
              <h1 className="mt-5 mb-3 md:text-xl text-base font-semibold tracking-wide" itemProp="headline">{ name }</h1>
                
              {/* 本文 */}
              <div className="md:my-0 md:mx-auto" style={{ paddingTop: 24, paddingBottom: 24, maxWidth: 976}}>
                {!!pageContext.content && (
                  <section itemProp="articleBody">{textContents}</section>
                )}
              </div>
              
              {/* FAQコンテンツ */}
              <div>{faqContents}</div>

              {/* シェア一旦非表示 */}
              {/* <div className="flex justify-end border-t-[1px] border-[#777] pt-5 mb-[50px] text-[12px]">
                <span className="pr-5">share</span><span className="pr-5"><img className="h-auto" width="21.56" height="17.52" alt="" src={icon_sns_SVG1}/></span>
                <span><img className="h-auto" width="20.53" height="20.53" alt="" src={icon_sns_SVG2}/></span>
              </div> */}

              <div className="bg-[#F8F8F8] rounded-blog mt-10 mb-10">
                <a href={`${indexUrl}categories/${setSubCateg?.slug}/`} className="flex px-5 py-5  md:text-sm">
                  <nav className="blog-post-nav w-full my-0 mx-auto" style={{ maxWidth: 976 }}>
                    <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', alignItems: 'center', listStyle: 'none', padding: 0 }}>
                      記事一覧へ戻る
                    </div>
                  </nav>
                </a>
              </div>              
            </div>
          </div>

          {/*2 column */}
          <aside className="column2_filed px-5 md:pt-0 pt-8">
            <div className="toc_mt">

              {/*目次 */}
              {/* {tableContents !== 'nodata'?
                <div className={"toc_filed hidden md:block mb-[20px] mt-[14px]"}>
                  <h3>目次</h3>
                  <dl>
                    {tableContents}
                  </dl>
                </div>
                :
                <></>
              } */}

              {/* バナー画像（横） */}
              {bannerArray_side?.map((result) => (
                <div className="md:mt-0 mt-5 mb-5">
                  <a className="" href={result.url} aria-label="banner" {...result.linktype !== '内部リンク' ? {target: '_blank', rel: 'noopener noreferrer'}: {}}>
                    <span className="hidden">{result.text}</span>
                    <div className="">
                      <GatsbyImage
                        image={getImage(result.image?.localFile?.childImageSharp?.gatsbyImageData)}
                        alt={result.title}
                      />
                    </div>
                  </a>
                </div>
              ))}

              {/*関連記事 */}
              <h3 className="mt-13 md:mt-8 mb-5 font-semibold flex">関連記事</h3>
              <div className="flex-wrap justify-between mb-10 md:block flex">
                {relatedList}
              </div>

              <div className="mb-10">
                {/*カテゴリ検索（PC） */}
                {searchList}
                {/*PCワード検索 */}
                {wordSearchContentPC}
              </div>

              {/* PCプロフィール */}
              {profilePC}

            </div>
          </aside>
        </div>

        <div>
          {/*ワード検索 */}
          {wordSearchContentMB}
          
          {/* MBプロフィール */}
          {profileMB}
        </div>

      </article>
      {/* 固定フッター */}
      <FixedFoot fixPattern='blog' item={tableContents} />
    </Layout>
  </>
  )
}
export default FxBlogPostTemplate